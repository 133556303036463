/** VARIABLES **/


// MEDIAQUERIES
$mediaqueries: (
	hover: 			"(hover: hover)",
	ie: 			"screen and (-ms-high-contrast: active), (-ms-high-contrast: none)",
	safari: 		"screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)",
	safari2: 		"not all and (min-resolution:.001dpcm)",
	landscape:		"(orientation: landscape)",
	portrait:		"(orientation: portrait)",
	mobS3: 			360,
	mobS2: 			480,
	mobS: 			640,
	mob: 			767,
	tabS: 			820,
	tab: 			991,
	lapS3: 			1024,
	lapS2: 			1199,
	lapS: 			1280,
	lap: 			1367,
	deskS: 			1440,
	desk: 			1560,
	deskB: 			1680
);


// GENERAL CONFIG
$widthLimit: 			1200px;
$paddingGeneral: 		25px;
$paddingGeneralMob: 	20px;


// COLORS
$black: 			#000;
$lBlack: 			#8a7e7e;

$lGray: 			#dfdfdf;

$white: 			#FFF;

$red: 				#FF0000;

$success: 			#219c3e;
$warning:			#d66519;
$error: 			$red;
$information: 		#2476ce;


// GLOBAL FONTS
$ftRegular:			arial, sans-serif;

$fontFamily: 		$ftRegular;
$baseFont: 			16;