.lines{
    position: absolute;
    z-index: 1;
    width: 12rem;
    pointer-events: none;
    animation-name: opacity;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    &--lineBanner1{
        bottom: -30px;
        left: -45px;
    }
    &--lineBanner2{
        right: -36px;
        top: 5rem;
    }
    &--lineSobremi1{
        left: -156px;
        top: 1rem;
        @include mq(911){
            display: none; 
        }
    }
    &--lineSobremi2{
        top: 25px;
        right: -42px;
        @include mq(911){
            top: auto;
            bottom: 1px; 
        }
    }
    &--lineProyectos1{
        left: -46px;
        top: 3.5rem;
        @include mq(911){
            display: none; 
        }
    }
    &--lineProyectos2{
        right: -46px;
        top: 3.5rem;
        @include mq(911){
            display: none; 
        }
    }
    &--lineFormulario1{
        left: -40px;
        top: 25px;
    }
    &--lineFormulario2{
        right: -38px;
        bottom: 21px;
    }
}

@keyframes opacity {
    from {
      opacity: 0.2;
    }
  
    to {
        opacity: 1;
    }
}