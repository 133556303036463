/** FONTS **/

@font-face {
    font-family: 'inter-regular';
	src:  url('../fonts/Inter-Regular.ttf')  format('truetype');
	font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'inter-bold';
	src:  url('../fonts/Inter-Bold.ttf')  format('truetype');
	font-weight: normal;
    font-style: normal;
}

// @import 'https://fonts.googleapis.com/css?family=Roboto';

// @font-face {
// 	font-family: 'bebas_neue_regularregular';
// 	src:  url('../fonts/BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
// 		url('../fonts/BebasNeueRegular.otf')  format('opentype'),
// 		url('../fonts/BebasNeueRegular.woff') format('woff'),
// 		url('../fonts/BebasNeueRegular.ttf')  format('truetype'),
// 		url('../fonts/BebasNeueRegular.svg#BebasNeueRegular') format('svg');
// 	font-weight: normal;
// 	font-style: normal;
// }