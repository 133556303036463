.footer{
    display: flex;
    width: 100%;
    height: 20vh;
    align-items: center;
    justify-content: center;
    background-color: #04151E;
    &--container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        p{
            font-family: 'inter-regular';
            color: white;
            font-size: 16px;
            line-height: 30px;
            text-align: center;
            @include mq(911){
                font-size: 14px;
            }
            span{
                font-family: 'inter-bold';
            }
        }
    }
}