/** MIXINS **/


// MEDIAQUERIES
@mixin mq($media: mob, $type: max){
	@if type-of($media) == number {
		@if unitless($media) {
			@media only screen and (#{$type}-width: $media + px){
				@content;
			}
		}
	} @else {
		@if type-of(map-get($mediaqueries, $media)) == number {
			@media only screen and (#{$type}-width: map-get($mediaqueries, $media) + px){
				@content;
			}
		} @else if type-of(map-get($mediaqueries, $media)) == string {
			@media #{map-get($mediaqueries, $media)}{
				@content;
			}
		}
	}
}


// FLEXBOX
@mixin flex( $option: false, $jc: center, $ai: center, $ac: center, $fw: wrap, $d: flex, $fd: row ) {
	display: $d;
	flex-flow: $fd $fw;
	@if $option == centerY {
		justify-content: flex-start;
		align-items: center;
		align-content: center;
	} @else if $option == centerX {
		justify-content: center;
		align-items: flex-start;
		align-content: flex-start;
	} @else if $option == noCenter {
		justify-content: flex-start;
		align-items: flex-start;
		align-content: flex-start;
	} @else {
		justify-content: $jc;
		align-items: $ai;
		align-content: $ac;
	}
}

@mixin btnPortafolio($wd:240px, $mg: 25px 0px 0px 0px) {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: $wd;
	height: 50px;
	background: #1D41DF;
	color: white;
	margin: $mg;
	border: none;
	cursor: pointer;
	transition: 3s ease all;
	position: relative;
	overflow: hidden;
	&::after {
		content: '';
		width: 100%;
		height: 300px;
		position: absolute;
		z-index: 1;
		background: #79EBE8;
		top: -300px;
		left: 0px;
		transition: 1s ease-in-out all;
		border-radius: 0px 0px 300px 300px;
	}
	&:hover::after {
		top: 0;
	}
	&:hover{
		span{
			color: #0B0B0B;
		}
	}
	span{
		position: relative;
		z-index: 2;
		transition: 2s ease all;
		font-family: 'inter-bold';
		font-size: 20px;
	}
}