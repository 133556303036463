/** GENERAL **/


html, body, body *, *, *::before, *::after {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	//overflow-x: hidden;
	position: relative;
	scroll-behavior: smooth;
}

body, html, h6, h5, h4, h3, h2, h1, p {
	font-family: $fontFamily;
	font-size: $baseFont + px;
	font-weight: 400;
}

img {
	display: inline-block;
	max-width: 100%;
}

a {
	outline: none;
	text-decoration: none;
	cursor: pointer;
	&:hover {
		text-decoration: none;
	}
}

a, button {
	outline: none;
}

ul, ol {
	padding: 0;
	margin: 0;
	list-style: none;
}

input:focus, textarea:focus, a:focus {
	outline: none;
}

picture {
	line-height: 0;
}
	
iframe[sandbox] {
	transform: scale(1);
}

a[href^="tel"] {
	color: inherit;
	text-decoration: none;
}

.hidden{
	display: none !important;
}