.header{
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 5;
    @include mq(1000){
       overflow: initial;
    }
    &--container{
        display: flex;
        width: 90%;
        flex-direction: row;
        align-items: center;
        padding: 20px 0px;
        @include mq(1000){
            justify-content: space-between;
        }
        &--logo{
            width: 20%;
            @include mq(1000){
                width: 60%;
                z-index: 2;
            }
            img{
                width: 50%;
                object-fit: contain;
            }
        }
        &--items{
            width: 60%;
            @include mq(1000){
                display: none;
            }
            &--menu{
                display: flex;
                justify-content: space-evenly;
                li{
                    a{
                        font-family: 'inter-regular';
                        color: white;
                        font-size: 16px;
                        &:hover{
                            color: #79EBE8;
                        }
                    }
                }
            }
        }
        &--redes{
            width: 20%;
            display: flex;
            justify-content: flex-end;
            @include mq(1000){
                display: none;
            }
            &--idioma{
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                border: 2px solid white;
                cursor: pointer;
                margin-right: 15px;
                p{
                    font-family: 'inter-regular';
                    color: white;
                    font-size: 16px;
                    padding: 5px;
                    width: 50px;
                }
                img{
                    margin-right: 6px;
                    width: 15%;
                    object-fit: contain;
                }
            }
        }
        
        .btnMenuMobile{
            display: none;
            @include mq(1000){
                display: inline-block;
                width:30px;
                height: 20px;
                position: relative;
                z-index: 2;
                overflow: hidden;
            }
            span{
                width: 100%;
                height: 4px;
                border-radius: 4px;
                display: block;
                position: absolute;
                background: #79EBE8;
                transition: all 0.25s ease;
                transform-origin : 0px 100%;
            }
            span:nth-child(2){
                top: calc(50% - 2px);
            }
            span:nth-child(3){
                bottom: 0;
            }
        }
    }
    .menu--mobile{
        display: none;
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100vh;
        background: #040404;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 3rem;
        &--items{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: flex-start;
            gap: 25px;
            width: 100%;
            height: 70vh;
            li{
                a{
                    font-family: 'inter-regular';
                    color: white;
                    font-size: 25px;
                    &:hover{
                        color: #79EBE8;
                    }
                }
            }
        }
        &--redes{
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 30vh;
            gap: 20px;
            &--idioma{
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;
                border: 2px solid white;
                cursor: pointer;
                margin-right: 15px;
                width: 80px;
                p{
                    font-family: 'inter-regular';
                    color: white;
                    font-size: 16px;
                    padding: 5px;
                    width: 50px;
                }
                img{
                    margin-right: 6px;
                    width: 15%;
                    object-fit: contain;
                }
            }
        }
    }
}

.visible_menu{
    position: fixed;
    .btnMenuMobile{
        height: 30px;
        span:nth-child(1){
            transform: rotate(45deg) translate(4px, -1px);
        }
        span:nth-child(2){
            opacity: 0;
        }
        span:nth-child(3){
            transform: rotate(-47deg) translate(6px, 3px);
        }
    }
    .menu--mobile{
        display: flex;
    }
}